import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 8-8-8-8\\@70% 1RM`}</p>
    <p>{`Strict Pullups 8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`400m run`}</p>
    <p>{`21-Ring Dips`}</p>
    <p>{`21-T2B`}</p>
    <p>{`400M Run`}</p>
    <p>{`15-Ring Dips`}</p>
    <p>{`15-T2B`}</p>
    <p>{`400M Run`}</p>
    <p>{`9-Ring Dips`}</p>
    <p>{`9-T2B`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      